import { Address } from "viem";
import { useQuery } from '@tanstack/react-query'

import { SupportedIndexes } from "../../constants/indexes";
import { SupportedChainIdType } from "../../constants/network";
import { getIndexContract, getVaultContract, getVaultPocketContract, IndexVaultsMetadata, SupportedPockets, VaultType } from "../../constants/vaults";
import { ChainTokens, getERC20Contract, SupportedTokens } from "../../constants/tokens";
import { useAddress, useChainId } from "../network";
import { getAaveV3PoolContract } from "../../constants/contracts";
import { calculateCompoundedRate, SECONDS_PER_YEAR } from "../../utils/aaveUtils";
import { normalize } from "../../utils/bignumber";


export type VaultPocketSnapshotType = {
  vaultAddress: Address
  collateralToken: SupportedTokens
  feeRecipient: Address
  interestRate: number,
  liquidationParams: {
    threshold: bigint,
    penalty: bigint,
    minHealthFactor: bigint,
    maxHealthFactor: bigint,
  }
  totalBalance: bigint
  totalShares: bigint
  collateralPrice: bigint
  collateralDecimals: number
  indexPrice: bigint
  aaveApy?: number
}

export type UserPocketSnapshotType = {
  vaultAddress: Address
  pocket: SupportedPockets
  pocketId: number
  pocketAddress: Address
  collateralToken: SupportedTokens
  collateral: bigint
  mintedAmount: bigint
  collateralAmountUsdc: bigint
  mintedAmountUsdc: bigint
  healthFactor: bigint
  outstandingInterest: bigint
}


export const fetchVaultPocketsSnapshot = async (chainId: SupportedChainIdType, supportedIndex: SupportedIndexes, vault: VaultType) => {
  const vaultContract = getVaultContract(chainId, vault.address)
  const collateralContract = getERC20Contract(chainId, vault.collateral)
  const aavePoolContract = getAaveV3PoolContract(chainId)
  const indexContract = getIndexContract(chainId, supportedIndex)
  const aaveReserveAddress = ChainTokens[chainId][vault.collateral]

  const pocketsOrder = new Array<SupportedPockets>()
  const callsPerPocket = !indexContract ? 5 : 6

  const vaultCalls = new Array<Promise<any>>()
  for (const pocket in vault.pockets) {
    const currentPocket = vault.pockets[pocket as SupportedPockets]
    if (currentPocket) {
      const pocketContract = getVaultPocketContract(chainId, currentPocket.address)
      pocketsOrder.push(pocket as SupportedPockets)
      vaultCalls.push(vaultContract.read.feeRecipient())
      vaultCalls.push(vaultContract.read.interestRate())
      vaultCalls.push(vaultContract.read.liquidationParams())
      vaultCalls.push(pocketContract.read.totalBalance())
      vaultCalls.push(pocketContract.read.totalShares())
      vaultCalls.push(vaultContract.read.latestPrice())
      vaultCalls.push(collateralContract.read.decimals())
      if (indexContract) {
        vaultCalls.push(indexContract.read.latestPrice())
        vaultCalls.push(aavePoolContract.read.getReserveData([aaveReserveAddress]))
      }
    }
  }
  const results = await Promise.all(vaultCalls)
  const pocketsSnapshot = Array<VaultPocketSnapshotType>()

  pocketsOrder.forEach((pocket, index) => {
    const vaultPocket = vault.pockets[pocket];
    if (vaultPocket !== undefined) {
      const currentLiquidityRate = indexContract ? results[index * callsPerPocket + 8].currentLiquidityRate : 0n

      const liq = calculateCompoundedRate({ rate: currentLiquidityRate, duration: SECONDS_PER_YEAR })
      pocketsSnapshot.push({
        vaultAddress: vault.address,
        collateralToken: vault.collateral,
        feeRecipient: results[index * callsPerPocket],
        interestRate: results[index * callsPerPocket + 1],
        liquidationParams: results[index * callsPerPocket + 2],
        totalBalance: results[index * callsPerPocket + 3],
        totalShares: results[index * callsPerPocket + 4],
        collateralPrice: results[index * callsPerPocket + 5],
        collateralDecimals: results[index * callsPerPocket + 6],
        indexPrice: indexContract ? results[index * callsPerPocket + 7] : 0n,
        aaveApy: indexContract ? parseFloat(normalize(liq, 27)) : 0,
      })
    }
  })

  return pocketsSnapshot;
}

export const fetchVaultsSnapshots = async (chainId: SupportedChainIdType) => { 
  const vaultsMetada = IndexVaultsMetadata[chainId]  
  const vaultsSnapshots = {} as Record<SupportedIndexes, VaultPocketSnapshotType[]>

  const indexes = Object.keys(vaultsMetada) as SupportedIndexes[]

  for (const index in indexes) { 
    for (let i = 0; i < vaultsMetada[indexes[index]].length; i++) { 
      const vault = vaultsMetada[indexes[index]][i]
      if (vault) {
        const pocketsSnapshot = await fetchVaultPocketsSnapshot(chainId, indexes[index], vault)
        if (vaultsSnapshots[indexes[index]] === undefined) {
          vaultsSnapshots[indexes[index]] = [...pocketsSnapshot]
        } else {
          vaultsSnapshots[indexes[index]].push(...pocketsSnapshot)
        }
      }
    }
  }

  return vaultsSnapshots;
}

export type VaultsSnapshotType = Record<SupportedIndexes, VaultPocketSnapshotType[]>;

export const useVaultSnapshots = () => { 
  const chainId = useChainId()
  
  return useQuery({
    queryKey: ['VaultsSnapshot', chainId],
    refetchInterval: 120000,
    queryFn: async () => {
      return await fetchVaultsSnapshots(chainId);
    },
  })
}

export const fetchUserVaultPocketsSnapshot = async (
  chainId: SupportedChainIdType,
  userAddress: Address,
  vault: VaultType,
) => { 
  const vaultContract = getVaultContract(chainId, vault.address)
  const vaultCalls = new Array<Promise<any>>()
  const pocketsOrder = new Array<SupportedPockets>()
  const callsPerPocket = 6

  for (const pocket in vault.pockets) {
    const currentPocket = vault.pockets[pocket as SupportedPockets]
    if (currentPocket) {
      pocketsOrder.push(pocket as SupportedPockets)
      vaultCalls.push(vaultContract.read.collateralOf([userAddress, BigInt(currentPocket.id)]))
      vaultCalls.push(vaultContract.read.mintedAmountOf([userAddress, BigInt(currentPocket.id)]))
      vaultCalls.push(vaultContract.read.collateralValueOfUser([userAddress, BigInt(currentPocket.id)]))
      vaultCalls.push(vaultContract.read.mintedValueOfUser([userAddress, BigInt(currentPocket.id)]))
      vaultCalls.push(vaultContract.read.healthFactor([userAddress, BigInt(currentPocket.id)]))
      vaultCalls.push(vaultContract.read.outstandingInterestOf([userAddress, BigInt(currentPocket.id)]))
    }
  }

  const results = await Promise.all(vaultCalls)
  const pocketsSnapshot = Array<UserPocketSnapshotType>()

  pocketsOrder.forEach((pocket, index) => {
    const vaultPocket = vault.pockets[pocket];
    if (vaultPocket !== undefined) {
      pocketsSnapshot.push({
        vaultAddress: vault.address,
        pocket: pocket,
        pocketId: vaultPocket.id,
        pocketAddress: vaultPocket.address,
        collateralToken: vault.collateral,
        collateral: results[index * callsPerPocket],
        mintedAmount: results[index * callsPerPocket + 1],
        collateralAmountUsdc: results[index * callsPerPocket + 2],
        mintedAmountUsdc: results[index * callsPerPocket + 3],
        healthFactor: results[index * callsPerPocket + 4],
        outstandingInterest: results[index * callsPerPocket + 5],
      })
    }
  })

  return pocketsSnapshot;
} 

export const fectchUserVaultsSnapshots = async (chainId: SupportedChainIdType, userAddress: Address) => { 
  const vaultsMetada = IndexVaultsMetadata[chainId]
  const userVaultsSnapshot = {} as Record<SupportedIndexes, UserPocketSnapshotType[]>

  const indexes = Object.keys(vaultsMetada) as SupportedIndexes[]
  for (const index in indexes) {
    for (let i = 0; i < vaultsMetada[indexes[index]].length; i++) {
      const vault = vaultsMetada[indexes[index]][i]
      if (vault) {
        const pocketsSnapshot = await fetchUserVaultPocketsSnapshot(chainId, userAddress, vault)
        if (userVaultsSnapshot[indexes[index]] === undefined) { 
          userVaultsSnapshot[indexes[index]] = [...pocketsSnapshot]
        } else {
          userVaultsSnapshot[indexes[index]].push(...pocketsSnapshot)
        }
      }
    }
  }

  return userVaultsSnapshot
}

export type UserVaultsSnapshotType = Record<SupportedIndexes, UserPocketSnapshotType[]>;

export const useUserVaultsSnapshots = () => { 
  const chainId = useChainId()
  const address = useAddress()
  
  return useQuery({
    queryKey: ['UserVaultsSnapshot', chainId, address],
    enabled: !!address.address,
    refetchInterval: 150000,
    queryFn: async () => {
      if (!address.address) return
      return await fectchUserVaultsSnapshots(chainId, address.address);
    },
  })
}
