import { Address, getAddress, getContract, WalletClient } from "viem";
import {
  arbitrum,
  arbitrumSepolia,
  base,
  baseSepolia,
  mainnet,
  sepolia,
} from "viem/chains";

import cbBtcLogo from '../assets/icons/cbBTC.png'
import lBtcLogo from '../assets/icons/lbtc.png'
import ethLogo from '../assets/icons/eth.png'
import usdcLogo from '../assets/icons/usdc.png'
import { ERC20Abi } from '../abi/ERC20.abi'
import { getPublicClient, SupportedChainIdType } from "./network";


export enum SupportedTokens {
  usdc = 'usdc',
  weth = 'weth',
  cbbtc = 'cbbtc',
  lbtc = 'lbtc',
}

export type TokensMetadataType = {
  [asset in SupportedTokens]: {
    name: string;
    symbol: string;
    decimals: number;
    displayDecimals: number;
    icon: string;
  };
};

export const TokenMetadata: TokensMetadataType = {
  [SupportedTokens.usdc]: {
    name: 'USDC',
    symbol: 'USDC',
    decimals: 6,
    displayDecimals: 2,
    icon: usdcLogo,
  },
  [SupportedTokens.weth]: {
    name: 'WETH',
    symbol: 'WETH',
    decimals: 18,
    displayDecimals: 2,
    icon: ethLogo,
  },
  [SupportedTokens.cbbtc]: {
    name: 'cbBtc',
    symbol: 'cbBTC',
    decimals: 8,
    displayDecimals: 2,
    icon: cbBtcLogo,
  },
  [SupportedTokens.lbtc]: {
    name: 'lBTC',
    symbol: 'lBTC',
    decimals: 8,
    displayDecimals: 2,
    icon: lBtcLogo,
  }
};

export const ChainTokens: { [chainId in SupportedChainIdType]: {
    [asset in SupportedTokens]: Address;
  };
} = {
  [mainnet.id]: {
    [SupportedTokens.cbbtc]: getAddress('0xcbB7C0000aB88B473b1f5aFd9ef808440eed33Bf'),
    [SupportedTokens.lbtc]: getAddress('0x8236a87084f8B84306f72007F36F2618A5634494'),
    [SupportedTokens.usdc]: getAddress('0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'),
    [SupportedTokens.weth]: getAddress('0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'),
  },
  [sepolia.id]: {
    [SupportedTokens.cbbtc]: getAddress('0xcbB7C0000aB88B473b1f5aFd9ef808440eed33Bf'),
    [SupportedTokens.lbtc]: getAddress('0x8236a87084f8B84306f72007F36F2618A5634494'),
    [SupportedTokens.usdc]: getAddress('0x94a9D9AC8a22534E3FaCa9F4e7F2E2cf85d5E4C8'),
    [SupportedTokens.weth]: getAddress('0x1E057193AC3f482E6794862a2EFfeB9FD0DbBD74'),
  },
  [base.id]: {
    [SupportedTokens.cbbtc]: getAddress('0xcbB7C0000aB88B473b1f5aFd9ef808440eed33Bf'),
    [SupportedTokens.lbtc]: getAddress('0xecAc9C5F704e954931349Da37F60E39f515c11c1'),
    [SupportedTokens.usdc]: getAddress('0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913'),
    [SupportedTokens.weth]: getAddress('0x4200000000000000000000000000000000000006'),
  },
  [baseSepolia.id]: {
    [SupportedTokens.cbbtc]: getAddress('0xcbB7C0000aB88B473b1f5aFd9ef808440eed33Bf'),
    [SupportedTokens.lbtc]: getAddress('0xecAc9C5F704e954931349Da37F60E39f515c11c1'),
    [SupportedTokens.usdc]: getAddress('0x036CbD53842c5426634e7929541eC2318f3dCF7e'),
    [SupportedTokens.weth]: getAddress('0x4200000000000000000000000000000000000006'),
  },
  [arbitrum.id]: {
    [SupportedTokens.cbbtc]: getAddress('0xcbB7C0000aB88B473b1f5aFd9ef808440eed33Bf'),
    [SupportedTokens.lbtc]: getAddress('0xecAc9C5F704e954931349Da37F60E39f515c11c1'),
    [SupportedTokens.usdc]: getAddress('0xaf88d065e77c8cC2239327C5EDb3A432268e5831'),
    [SupportedTokens.weth]: getAddress('0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'),  
  },
  [arbitrumSepolia.id]: {
    [SupportedTokens.cbbtc]: getAddress('0xcbB7C0000aB88B473b1f5aFd9ef808440eed33Bf'),
    [SupportedTokens.lbtc]: getAddress('0xecAc9C5F704e954931349Da37F60E39f515c11c1'),
    [SupportedTokens.usdc]: getAddress('0xaf88d065e77c8cC2239327C5EDb3A432268e5831'),
    [SupportedTokens.weth]: getAddress('0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'),
  },
}

export const getERC20Contract = (chainId: SupportedChainIdType, token: SupportedTokens, signer?: WalletClient) => { 
  const publicClient = getPublicClient(chainId)
  const tokenAddress = ChainTokens[chainId][token]

  return getContract({
    address: tokenAddress,
    abi: ERC20Abi,
    client: signer ? { public: publicClient, wallet: signer } : publicClient,
  })
}

export const getERC20ContractByAddress = (chainId: SupportedChainIdType, address: Address, signer?: WalletClient) => { 
  const publicClient = getPublicClient(chainId)

  return getContract({
    address,
    abi: ERC20Abi,
    client: signer ? { public: publicClient, wallet: signer } : publicClient,
  })
}
