import { Address, getAddress } from "viem";
import { arbitrum, arbitrumSepolia, base, baseSepolia, mainnet, sepolia } from 'viem/chains';

import { SupportedChainIdType } from "./network";
import meemLogo from '../assets/icons/meem.png';
import tcapLogo from '../assets/tcap-coin.png';
import { notEmpty } from "../utils/utils";


export const IndexesPriceFeedUrl = 'https://api.perps.cryptex.finance/price-feed';

export enum SupportedIndexes {
  meem = 'meem',
  tcap = 'tcap',
}

export enum SupportedPriceFeeds {
  pyth = 'pyth',
  chainlink = 'chainlink',
}

export enum IndexProducts {
  oneToOne = 'oneToOne',
  perp = 'perp',
  vault = 'vault',
}

export type IndexMetadataType = {
  [index in SupportedIndexes]: {
    icon: string
    name: string
    symbol: string
    products: IndexProducts[]
    decimals: number
    displayDecimals: number
  }
};


export const IndexMetadata: IndexMetadataType = {
  [SupportedIndexes.meem]: {
    icon: meemLogo,
    symbol: "meem",
    name: "Cryptex Meemcoin Index",
    products: [IndexProducts.oneToOne, IndexProducts.perp],
    decimals: 18,
    displayDecimals: 4,
  },
  [SupportedIndexes.tcap]: {
    icon: tcapLogo,
    symbol: "tcap",
    name: "Total Crypto Market Cap Index",
    products: [IndexProducts.vault],
    decimals: 18,
    displayDecimals: 4,
  },
}

export const ChainIndex: { [chainId in SupportedChainIdType]: {
  [asset in SupportedIndexes]?: {
    token: Address,
    priceFeeds: { [SupportedPriceFeeds.pyth]: string | undefined, [SupportedPriceFeeds.chainlink]: string | undefined },
  };
  };
} = {
  [mainnet.id]: {
    [SupportedIndexes.meem]: {
      token: getAddress('0xA544b3F0c46c15F0B2b00ba3D67b56C250287905'),
      priceFeeds: {
        [SupportedPriceFeeds.pyth]: "0xa217ab749c14596d69a6206c34bda27188dcfaf9d38dfcd9b76a0b348e78be44",
        [SupportedPriceFeeds.chainlink]: "0xa217ab749c14596d69a6206c34bda27188dcfaf9d38dfcd9b76a0b348e78be44",
      }
    },
    [SupportedIndexes.tcap]: {
      token: getAddress('0x30a465f72Ed6792e2f605D365f34d2Ae21898DdF'),
      priceFeeds: {
        [SupportedPriceFeeds.pyth]: undefined,
        [SupportedPriceFeeds.chainlink]: "0xa217ab749c14596d69a6206c34bda27188dcfaf9d38dfcd9b76a0b348e78be44",
      },
    },
  },
  [sepolia.id]: {
    [SupportedIndexes.tcap]: {
      token: getAddress('0x4444238f07cA5d8a0EfE67B1E9AD6231a258F5e4'),
      priceFeeds: {
        [SupportedPriceFeeds.pyth]: undefined,
        [SupportedPriceFeeds.chainlink]: "0x44e9D3A83e94346e10C10Bf17e7E0c43aDf74458",
      },
    }
  },
  [base.id]: {
    [SupportedIndexes.tcap]: {
      token: getAddress('0x4e99472385a2522aa292b008Da294a78F420A367'),
      priceFeeds: {
        [SupportedPriceFeeds.pyth]: undefined,
        [SupportedPriceFeeds.chainlink]: "0xaC1A2Bf3Db803F9FFC8EC39EA26c70622A0ed461",
      },
    }
  },
  [baseSepolia.id]: {
    [SupportedIndexes.meem]: {
      token: getAddress('0xc0B5eA86EcE2104D124A418e5012Da7272bB943b'),
      priceFeeds: {
        [SupportedPriceFeeds.pyth]: "0xa217ab749c14596d69a6206c34bda27188dcfaf9d38dfcd9b76a0b348e78be44",
        [SupportedPriceFeeds.chainlink]: undefined,
      }
    },
    [SupportedIndexes.tcap]: {
      token: getAddress('0x18a849b56a97B52CA5F1B0f66F3cf7bC4Bf0ECe2'),
      priceFeeds: {
        [SupportedPriceFeeds.pyth]: undefined,
        [SupportedPriceFeeds.chainlink]: "0x5f67D7428a15D4f0bdFF41B4480BEDdd99DBa8cb",
      },
    }
  },
  [arbitrum.id]: {
    [SupportedIndexes.meem]: {
      token: getAddress('0xA544b3F0c46c15F0B2b00ba3D67b56C250287905'),
      priceFeeds: {
        [SupportedPriceFeeds.pyth]: "0xa217ab749c14596d69a6206c34bda27188dcfaf9d38dfcd9b76a0b348e78be44",
        [SupportedPriceFeeds.chainlink]: undefined,
      }
    },
    [SupportedIndexes.tcap]: {
      token: getAddress('0x30a465f72Ed6792e2f605D365f34d2Ae21898DdF'),
      priceFeeds: {
        [SupportedPriceFeeds.pyth]: undefined,
        [SupportedPriceFeeds.chainlink]: "0x4763b84cdBc5211B9e0a57D5E39af3B3b2440012",
      }
    }
 },
  [arbitrumSepolia.id]: {},
}

export const chainIndexWithAddress = (
  chainId: SupportedChainIdType
): Array<{ asset: SupportedIndexes; indexAddress: { token: `0x${string}` } }> => {
  return Object.entries(ChainIndex[chainId])
    .map(([asset, indexAddress]) =>
      !!indexAddress
        ? {
            asset: asset as SupportedIndexes,
            indexAddress,
          }
        : null
    )
    .filter(notEmpty);
};

export const addressToIndex = (address: Address) => {
  for (const chainId of Object.keys(ChainIndex)) {
    for (const asset of Object.keys(
      ChainIndex[Number(chainId) as SupportedChainIdType]
    )) {
      if (
        ChainIndex[Number(chainId) as SupportedChainIdType][
          asset as SupportedIndexes
        ]?.token === address
      ) {
        return asset as SupportedIndexes;
      }
    }
  }
}

