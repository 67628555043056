import React from "react";
import { Stack } from "react-bootstrap";
// import { Fuul } from "@fuul/sdk";

// import GiftPic from '../../../assets/gift.png';
// import CtxIcon from '../../../assets/ctx-icon.png';


export const PointsProgram = () => {
  /// Fuul.init({ apiKey: process.env.REACT_APP_FUUL_API_KEY || "" });
  /* useEffect(() => {
    const load = async () => {
      const points = await Fuul.getPointsLeaderboard({ currency_address: "0xbb22ff867f8ca3d5f2251b4084f6ec86d4666e14", page: 1, page_size: 10 });
      
    }
    load();
  }, []); */

  return (
    <Stack direction="vertical" gap={1} className="py-3 px-1 w-70 align-self-center border-box">
      <Stack className="py-2 px-3" gap={2}>
        <h4>TCAP Points Program</h4>
        <Stack gap={2}>
          <p className="text-muted-light">
            Earn points to participate on the weekly CTX raffle. The more points you have, the more chances you have to win.
          </p>
          <ul>
            <li className="text-muted-light">
              Earn <span className="text-purple bold">1 point</span> with every <span className="text-purple bold">TCAP/ETH</span> swap greater than $500 total value.
            </li>
            <li className="text-muted-light">
              Earn <span className="text-purple bold">0.15 points</span> per day per <span className="text-purple bold">TCAP token</span>
              {" "}held in your account that was purchased directly from the <span className="text-purple bold"> Uniswap DEX pool</span>.
            </li>
          </ul>
        </Stack>
      </Stack>
      <Stack className="py-3 px-4" direction="vertical" gap={4}>
        <span className="text-purple text-center py-5" style={{ fontSize: "1.5rem" }}>
          Coming Soon!
        </span>
        {/* <Stack direction="horizontal" gap={2} className="px-3 py-2 border-box-secondary justify-content-between">
           <Stack direction="horizontal" gap={2} className="align-items-center">
            <Image src={GiftPic} height={26} width={26} />
            <p style={{ fontSize: "1.2rem", color: "#e1e0ec" }}>
              Congratulations you won <span className="text-purple">1000 CTX</span>
            </p>
          </Stack>
          <Stack direction="horizontal" className="justify-content-end">
            <Button style={{ width: "7rem" }}>
              Claim
            </Button>
          </Stack>
        </Stack>  */}
        {/* <Stack direction="vertical">
          <Stack direction="horizontal" className="px-3 mb-2">
            <Stack className="w-25">
              <span className="text-muted small">Rank</span>
            </Stack>
            <Stack className="w-45 text-muted">
              <span className="text-muted small">Address</span>
            </Stack>
            <Stack className="w-30 text-muted align-items-end">
              <span className="text-muted small">Points</span>
            </Stack>
          </Stack>
          <Stack className="vertical" gap={1}>
            <Stack key="you" direction="horizontal" className="px-3 py-3 border-box">
              <Stack className="w-25">
                <span className="text-purple zoom-2">3</span>
              </Stack>
              <Stack className="w-45">
                <span className="text-purple zoom-2">You</span>
              </Stack>
              <Stack className="w-30 align-items-end">
                <span className="text-purple zoom-2">400</span>
              </Stack>
            </Stack>  
            {leaderboard.map((entry, index) => (
              <Stack
                key={index}
                direction="horizontal"
                className={`px-3 py-3 ${index % 2 === 0 ? "border-box-light" : "border-box"}`}
              >
                <Stack className="w-25">
                  <span>{entry.rank}</span>
                </Stack>
                <Stack className="w-45">
                  <span>{entry.address}</span>
                </Stack>
                <Stack className="w-30 align-items-end">
                  <span>{entry.points}</span>
                </Stack>
              </Stack>
            ))}
          </Stack>  
        </Stack> */}
      </Stack>
    </Stack>
  );
}

/* const leaderboard = [
  {
    rank: 1,
    address: "0xBfBAdD58B65B54D1a5cEa6d9c730fbd57c182d32",
    points: 1000
  },
  {
    rank: 2,
    address: "0xg9BAdD58B65B54D1a5c216d9c730fbd57c182d56",
    points: 900
  },
  {
    rank: 4,
    address: "0xBfBAdD58B65B54D1a5cEa6d9c730fbd57c182d32",
    points: 800
  },
  {
    rank: 5,
    address: "0x3a82D8754D81b5f32f6ef6606154b29B1eDEB27f",
    points: 700
  },
  {
    rank: 6,
    address: "0xg9BAdD58B65B54D1a5c216d9c730fbd57c182d56",
    points: 600
  },
  {
    rank: 7,
    address: "0x3a82D8754D81b5f32f6ef6606154b29B1eDEB27f",
    points: 500
  },
  {
    rank: 8,
    address: "0xF6a16a48099497C59e8abEAa37Bb37B2F9B793d4",
    points: 400
  },
  {
    rank: 9,
    address: "0x3a82D8754D81b5f32f6ef6606154b29B1eDEB27f",
    points: 300
  },
  {
    rank: 10,
    address: "0xBfBAdD58B65B54D1a5cEa6d9c730fbd57c182d32",
    points: 200
  },
] */