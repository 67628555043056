import { MultiInvokerAddresses } from '@perennial/sdk'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Address, formatUnits, parseAbi, zeroAddress } from 'viem'
import { arbitrum } from 'viem/chains'
import { useAccount, useBalance, usePublicClient, useWalletClient } from 'wagmi'

import { ChainalysisContractAddress } from '../constants/contracts'

import { usePerennialSDKContext } from '../contexts/perennialSdkContext'
import { useDSU, useMarketFactory, useUSDC, useVaultFactory } from './contracts'
import { useAddress, useChainId, usePerpetualsChainId } from './network'
import { getERC20Contract, SupportedTokens, TokenMetadata } from '../constants/tokens'


export type Balances =
  | {
      usdc: bigint
      usdcAllowance: bigint
      dsuAllowance: bigint
    }
  | undefined

export const useBalances = () => {
  const chainId = usePerpetualsChainId()
  const { address } = useAddress()
  const usdcContract = useUSDC()
  const dsuContract = useDSU()

  return useQuery({
    queryKey: ['balances', chainId, address],
    enabled: !!address,
    queryFn: async () => {
      if (!address || !chainId) return

      const [usdcBalance, usdcAllowance, dsuAllowance] = await Promise.all([
        usdcContract.read.balanceOf([address]),
        usdcContract.read.allowance([address, MultiInvokerAddresses[chainId]]),
        dsuContract.read.allowance([address, MultiInvokerAddresses[chainId]]),
      ])

      return {
        usdc: usdcBalance,
        usdcAllowance,
        dsuAllowance,
      }
    },
  })
}

export const useOperators = () => {
  const chainId = usePerpetualsChainId()
  const marketFactory = useMarketFactory()
  const vaultFactory = useVaultFactory()
  const { address } = useAddress()

  return useQuery({
    queryKey: ['operators', chainId, address],
    enabled: !!address,
    queryFn: async () => {
      if (!address || !chainId) return

      return {
        marketFactoryApproved: await marketFactory.read.operators([address, MultiInvokerAddresses[chainId]]),
        vaultFactoryApproved: await vaultFactory.read.operators([address, MultiInvokerAddresses[chainId]]),
      }
    },
  })
}

export const useOperatorTransactions = () => {
  const chainId = usePerpetualsChainId()
  const queryClient = useQueryClient()
  const { data: walletClient } = useWalletClient()
  const { address } = useAddress()
  const sdk = usePerennialSDKContext()

  const onApproveMultiInvokerOperator = async () => {
    try {
      if (!walletClient) throw new Error('No wallet client provided')
      const { to, data, value } = await sdk.operator.build.approveMarketFactoryTx()
      const hash = await walletClient.sendTransaction({ to, data, value })
      const receipt = await sdk.publicClient.waitForTransactionReceipt({ hash })
      await queryClient.invalidateQueries({ queryKey: ['operators', chainId, address] })
      return receipt
    } catch (err) {
      // prevent error if user rejects tx
      return
    }
  }

  return {
    onApproveMultiInvokerOperator,
  }
}

export const useIsSanctioned = () => {
  const { address } = useAddress()
  const publicClient = usePublicClient({ chainId: arbitrum.id })

  return useQuery({
    queryKey: ['chainalysis_sanctioned', address],
    enabled: !!address && !!publicClient,
    queryFn: async () => {
      if (!address || !publicClient) return

      return publicClient.readContract({
        address: ChainalysisContractAddress,
        abi: parseAbi(['function isSanctioned(address) view returns (bool)'] as const),
        functionName: 'isSanctioned',
        args: [address],
      })
    },
  })
}


export const useNativeBalance = () => {
  const { chainId, address } = useAccount()
  const { data, isLoading, refetch } = useBalance({
    chainId,
    address,
    query: {
      enabled: !!address,
      refetchInterval: 120000,
    },
  });

  return {
    data,
    isLoading,
    refetchBalance: refetch,
  }
}

export const useTokenBalances = (token: SupportedTokens, spender?: Address) => { 
  const chainId = useChainId()
  const { address: userAddress } = useAddress()

  return useQuery({
    queryKey: ['TokenBalances', chainId, token, userAddress, spender],
    enabled: !!userAddress,
    queryFn: async () => {
      if (!userAddress) return { balance: 0n, allowance: 0n }

      const contract = getERC20Contract(chainId, token)

      const balancesCalls = await Promise.all([
        contract.read.balanceOf([userAddress]),
        contract.read.allowance([userAddress, spender || zeroAddress]),
      ])

      return {  balance: balancesCalls[0], allowance: balancesCalls[1] };
    },
  })
}

export const useSupportedTokensBalance = () => {
  const chainId = useChainId()
  const { address: userAddress } = useAddress()

  return useQuery({
    queryKey: ['SupportedTokensBalance', chainId, userAddress],
    enabled: !!userAddress,
    refetchInterval: 60000,
    queryFn: async () => {
      const balances = {} as Record<SupportedTokens, { balance: number, balanceBI: bigint }>;
      
      if (!userAddress) return balances;

      const balancesCalls = Object.keys(SupportedTokens).map((token) => { 
        const contract = getERC20Contract(chainId, token as SupportedTokens)
        return contract.read.balanceOf([userAddress]);
      })
      const response = await Promise.all(balancesCalls)

      Object.keys(SupportedTokens).forEach((token, index) => {
        const tokenMetadata = TokenMetadata[token as SupportedTokens]
        balances[token as SupportedTokens] = {
          balance: parseFloat(formatUnits(response[index], tokenMetadata.decimals)),
          balanceBI: response[index]
        }
      })
      return balances
    },
  })
}