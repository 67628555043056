import React, { useContext, useEffect, useState } from "react";
import { arbitrum, base, baseSepolia, mainnet, sepolia } from "viem/chains";
import { useLocation } from "react-router-dom";
import { CryptexProducts } from "../constants/network";

export interface ICryptexProductsContext { 
  product: CryptexProducts,
  setCurrentProduct: (productVersion: CryptexProducts) => void;
  makerType: number;
  setCurrentMakerType: (type: number) => void;
  getDefaultChain: () => any;
  getDefaultChainId: () => number;
  isProductSupportedChain: (chainId: number) => boolean;
  isSpot: () => boolean;
  isGovernance: () => boolean;
  isIndexes: () => boolean;
  isPerpetuals: () => boolean;
  isPerpetualsV1: () => boolean;
  isV1: () => boolean;
};

export const CryptexProductsContext = React.createContext<ICryptexProductsContext>({
  product: CryptexProducts.Perpetuals,
  setCurrentProduct: (products: CryptexProducts) => { },
  makerType: 0,
  setCurrentMakerType: (type: number) => { },
  getDefaultChain: () => mainnet,
  getDefaultChainId: () => mainnet.id,
  isProductSupportedChain: (chainId: number) => true,
  isSpot: () => true,
  isGovernance: () => false,
  isIndexes: () => false,
  isPerpetuals: () => false,
  isPerpetualsV1: () => false,
  isV1: () => false,
});


export const CryptexProductsProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const [product, setProduct] = useState<CryptexProducts>(CryptexProducts.Perpetuals);
  const setCurrentProduct = React.useCallback((currentProduct: CryptexProducts): void => {
    setProduct(currentProduct);
  }, []);
  const [makerType, setMakerType] = useState(0);
  const setCurrentMakerType = React.useCallback((type: number): void => {
    setMakerType(type);
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const marketFromParams = urlParams.get('market')?.toLowerCase()
    const vaultTypeFromParams = urlParams.get('vault-type')?.toLowerCase()

    if (location.pathname.includes("governance")) {
      setProduct(CryptexProducts.Governance);
    } else if (location.pathname.includes("spot")) {
      setProduct(CryptexProducts.Spot);
    } else if (location.pathname.includes("indexes")) {
      setProduct(CryptexProducts.Indexes);
    } else {
      if (marketFromParams === "tcap" || vaultTypeFromParams === "tcap") { 
        setProduct(CryptexProducts.PerpetualsV1);
      } else {
        setProduct(CryptexProducts.Perpetuals);
      }
    }
  }, [location]);

  const getDefaultChain = (): any => { 
    if (isPerpetuals() || isPerpetualsV1()) {
      return arbitrum;
    } else if (isIndexes()) {
      return base;
    } else {
      return mainnet;
    }
  }

  const getDefaultChainId = (): number => { 
    if (isPerpetuals() || isPerpetualsV1()) {
      return arbitrum.id;
    } else if (isIndexes()) {
      return base.id;
    } else {
      return mainnet.id;
    }
  }

  const isProductSupportedChain = (chainId: number): boolean => { 
    if (isGovernance()) {
      return chainId === mainnet.id;
    } else if (isIndexes()) {
      return chainId === base.id || chainId === sepolia.id || chainId === baseSepolia.id;
    } else if (isPerpetuals()) {
      return chainId === arbitrum.id;
    } else if (isPerpetualsV1()) { 
      return chainId === arbitrum.id;
    } else {
      return chainId === arbitrum.id || chainId === mainnet.id;
    }
  }

  const isSpot = (): boolean => { 
    return product === CryptexProducts.Spot;
  }

  const isGovernance = (): boolean => {
    return product === CryptexProducts.Governance;
  }
  
  const isIndexes = (): boolean => {
    return product === CryptexProducts.Indexes;
  }

  const isPerpetuals = (): boolean => {
    return product === CryptexProducts.Perpetuals;
  }

  const isPerpetualsV1 = (): boolean => {
    return product === CryptexProducts.PerpetualsV1;
  }

  const isV1 = (): boolean => {
    return product === CryptexProducts.V1;
  }

  return (
    <CryptexProductsContext.Provider
      value = {{
        product,
        setCurrentProduct,
        makerType,
        setCurrentMakerType,
        getDefaultChain,
        getDefaultChainId,
        isProductSupportedChain,
        isSpot,
        isGovernance,
        isIndexes,
        isPerpetuals,
        isPerpetualsV1,
        isV1
      }}
    >
      { children }
    </CryptexProductsContext.Provider>
  );
};

export const useCryptexProductsContext = () => {
  const context = useContext(CryptexProductsContext)
  if (context === undefined) {
    throw new Error('useCryptexProductsContext must be used within a CryptexProductsProvider')
  }
  return context
}
