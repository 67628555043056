import React from "react";
import { Stack } from "react-bootstrap";

import { CtxIncentive } from "./components/CtxIncentive";
import { PointsProgram } from "./components/PointsProgram";

const Incentives = () => {

  return (
    <Stack direction="vertical" className="p-5" gap={4}>
      <CtxIncentive />
      <PointsProgram />
    </Stack>
  );
}

export default Incentives;
