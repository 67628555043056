import { useCallback } from "react"
import { formatUnits, parseUnits } from "viem"
import { useTranslation } from 'react-i18next'

import { UserPocketSnapshotType, VaultPocketSnapshotType } from "../../hooks/vaults"
import { isNumbersOnly } from "../../utils/utils"
import { useIndexVaultContext } from "../../contexts/indexVaultContext"
import { FormNames, MinSafeHealthFactorPct, VaultTxList } from "./constants"
import { calcHealthFactor, calcDebtFromCollateralAndHealthFactor } from "./utils"
import { IndexMetadata } from "../../constants/indexes"


interface OnChangeHandlersProps {
  setValue: (valueName: FormNames, value: string) => void,
  collateral: bigint
  indexAmount: bigint
  healthFactor: bigint
  healthFactorFixed: boolean
  currentVaultSnapshot?: VaultPocketSnapshotType
}

export const useOnchangeHandlers = ({
  setValue,
  collateral,
  indexAmount,
  healthFactor,
  currentVaultSnapshot,
}: OnChangeHandlersProps) => { 
  const { selectedIndex } = useIndexVaultContext()

  const onChangeCollateral = useCallback((newCollateral: string, healthFactorFixed: boolean) => { 
    if (!isNumbersOnly(newCollateral) || !currentVaultSnapshot) return
    
    const validatedCollateral = parseUnits(newCollateral, currentVaultSnapshot.collateralDecimals)
    setValue(FormNames.collateral, newCollateral)
    
    if (!healthFactorFixed) { 
      const newHealthFactor = calcHealthFactor(
        validatedCollateral,
        indexAmount,
        currentVaultSnapshot.collateralPrice,
        currentVaultSnapshot.indexPrice,
        currentVaultSnapshot.collateralDecimals,
        IndexMetadata[selectedIndex].decimals
      )
      setValue(FormNames.healthFactor, newHealthFactor.toString())
    } else {
      const newIndexAmount = calcDebtFromCollateralAndHealthFactor(
        validatedCollateral,
        parseFloat(formatUnits(healthFactor, 18)) * MinSafeHealthFactorPct,
        currentVaultSnapshot.collateralPrice,
        currentVaultSnapshot.indexPrice,
        currentVaultSnapshot.collateralDecimals,
        IndexMetadata[selectedIndex].decimals
      )
      setValue(FormNames.indexAmount, newIndexAmount.toFixed(10))
    }
  }, [setValue, selectedIndex, currentVaultSnapshot, indexAmount, healthFactor])

  const onChangeIndexAmount = useCallback((newIndexAmount: string) => { 
    if (!isNumbersOnly(newIndexAmount) || !currentVaultSnapshot) return
    
    const validatedIndexAmount = parseUnits(newIndexAmount, IndexMetadata[selectedIndex].decimals)
    setValue(FormNames.indexAmount, newIndexAmount)

    const newHealthFactor = calcHealthFactor(
      collateral,
      validatedIndexAmount,
      currentVaultSnapshot.collateralPrice,
      currentVaultSnapshot.indexPrice,
      currentVaultSnapshot.collateralDecimals,
      IndexMetadata[selectedIndex].decimals
    )
    setValue(FormNames.healthFactor, newHealthFactor.toString())

      /* const newCollateral = calcDebtFromCollateralAndHealthFactor(
        collateral,
        parseFloat(formatUnits(healthFactor, 18)),
        currentVaultSnapshot.collateralPrice,
        currentVaultSnapshot.indexPrice,
        currentVaultSnapshot.collateralDecimals,
        IndexMetadata[selectedIndex].decimals
      )
      setValue(FormNames.collateral, newCollateral.toString()) */
  }, [setValue, selectedIndex, currentVaultSnapshot, collateral])

  return { onChangeCollateral, onChangeIndexAmount }
}

export const useValidators = ({
  currentVaultTx,
  collateralBalance,
  currentUserPocketSnapshot
}: {
  currentVaultTx: VaultTxList,
  collateralBalance: bigint,
  currentUserPocketSnapshot: UserPocketSnapshotType | undefined
}) => {
  const { t } = useTranslation()
  const { selectedIndex, currentVaultSnapshot } = useIndexVaultContext()

  const validateCollateral = useCallback((collateral: string) => { 
    if (collateral.trim() === "") return ""
    if (!isNumbersOnly(collateral)) return t("error.must-be-number", { field: t("collateral") })

    const collateralBI = parseUnits(collateral, currentVaultSnapshot?.collateralDecimals || 6)
    if (collateralBI === 0n) return t("error.must-be-greater-than-value", { field: t("collateral"), value: "0" })
    
    if (currentVaultTx === VaultTxList.deposit || currentVaultTx === VaultTxList.depositMint) {
      if (collateralBI > collateralBalance) return t("error.insufficient-balance2")
    } else if (currentVaultTx === VaultTxList.withdraw || currentVaultTx === VaultTxList.burnWithdraw) {
      if (collateralBI > (currentUserPocketSnapshot?.collateral || 0n)) return t("error.insufficient-collateral-vault")
    }  

    return ""
  },
    // eslint-disable-next-line
    [collateralBalance, currentUserPocketSnapshot, currentVaultTx, currentVaultSnapshot]
  )

  const validateIndexAmount = useCallback((indexAmount: string) => { 
    if (indexAmount.trim() === "") return ""
    if (!isNumbersOnly(indexAmount)) return t("error.must-be-number", { field: t("amount") })
    if (parseUnits(indexAmount, IndexMetadata[selectedIndex].decimals) === 0n) return t("error.must-be-greater-than-value", { field: t("amount"), value: "0" })
    if (currentVaultTx === VaultTxList.burn || currentVaultTx === VaultTxList.burnWithdraw) { 
      const indexAmountBI = parseUnits(indexAmount, IndexMetadata[selectedIndex].decimals)
      if (indexAmountBI > (currentUserPocketSnapshot?.mintedAmount || 0n)) return t("error.insufficient-minted-amount")
    }

    return ""
  },
    // eslint-disable-next-line
    [selectedIndex, currentUserPocketSnapshot, currentVaultTx]
  )

  return { validateCollateral, validateIndexAmount }
}
