import React, { useMemo } from "react";
import { Button, Image, Stack } from "react-bootstrap";
import { Fuul } from "@fuul/sdk";

import { SupportedTokens, TokenMetadata } from "../../../constants/tokens";
import { IndexMetadata, SupportedIndexes } from "../../../constants/indexes";
// import UniswapLogo from '../../assets/icons/uniswap.png';
import UniswapLogo2 from '../../../assets/icons/uniswap2.png';
import CtxIcon from '../../../assets/ctx-icon.png';
import { useUserRewards } from "../../../hooks/incentives";

const UniswapV3Pool = "https://app.uniswap.org/explore/pools/base/0x2B70CeA59a418d77265482564610EF4D681D5ad6";

export const CtxIncentive = () => {
  const tcapMetadata = IndexMetadata[SupportedIndexes.tcap]; 
  const wethMetadata = TokenMetadata[SupportedTokens.weth];
  const { data } = useUserRewards();
  
  Fuul.init({ apiKey: process.env.REACT_APP_FUUL_API_KEY || "" });

  const { userRewards } = useMemo(() => { 
    if (!data) return { rewards: [] };
    return { userRewards: data.userRewards };
  }, [data]);

  return (
    <Stack direction="vertical" gap={4} className="py-3 px-1 w-70 align-self-center border-box">
      <Stack className="py-2 px-3" gap={2}>
        <h4>TCAP Liquidity Providers Rewards</h4>
        <Stack>
          <p className="text-muted-light">
            A total of <span className="text-purple">178,000 CTX</span> will be distributed among
            {" "}<a href={UniswapV3Pool} target="_blank" rel="noreferrer" >TCAP/ETH pool</a> liquidity providers. To be eligible,
            liquidity must stay in range, with rewards distributed weekly.
          </p>
        </Stack>
      </Stack>
      <Stack className="py-2 px-3" direction="vertical" gap={4}>
        <Stack direction="vertical" gap={1}>
          <Stack direction="horizontal" gap={2} className="px-3 py-1">
            <Stack className="w-30">
              <span className="small text-muted">Pool</span>
            </Stack>
            <Stack className="w-23 align-items-end">
              <span className="small text-muted">Claimed</span>
            </Stack>  
            <Stack className="w-22 align-items-end">
              <span className="small text-muted">Available to Claim</span>
            </Stack>
            <Stack className="w-25" />
          </Stack>
          <Stack direction="horizontal" gap={2} className="border-box-light px-3 py-2">
            <Stack direction="horizontal" gap={2} className="w-30">
              <Stack direction="horizontal" gap={1}>
                <Image src={tcapMetadata.icon} height={39} width={39} />
                <Image src={wethMetadata.icon} height={36} width={36} />
              </Stack>
              <Stack direction="vertical" gap={0}>
                <span>
                  {tcapMetadata.symbol.toUpperCase()} / ETH Pool
                </span>
                <Stack direction="horizontal" gap={1} className="aling-items-center" style={{ marginTop: "-5px" }}>
                  <Image src={UniswapLogo2} height={16} width={16} />
                  <span className="small text-muted mt-1">Uniswap V3</span>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="horizontal" gap={1} className="w-23 justify-content-end align-items-center">
              <span>0.0000</span>
              <Image src={CtxIcon} width={24} height={24} />
            </Stack>
            <Stack direction="horizontal" gap={1} className="w-22 justify-content-end align-items-center">
              <span>0.0000</span>
              <Image src={CtxIcon} width={24} height={24} />
            </Stack>
            <Stack className="w-25 justify-content-center align-items-end">
              <Button disabled style={{ width: "7rem" }}>
                Claim
              </Button>
            </Stack>  
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
