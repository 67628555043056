import { useQuery } from '@tanstack/react-query'
import { gql } from '@apollo/client'

import { useChainId, useIndexesGraphClient } from '../network'
import { GraphDefaultPageSize } from '../../utils/graphUtils'
import { IndexMetadata, SupportedIndexes } from '../../constants/indexes'
import { formatUnits } from 'viem'


export const useIndexChartPrices = (index: SupportedIndexes, fromTimestamp: number, toTimestamp?: number) => {
  const chainId = useChainId()
  const graphClient = useIndexesGraphClient()

  return useQuery({
    queryKey: ['indexGraphPrices', chainId, fromTimestamp, toTimestamp],
    enabled: true,
    queryFn: async () => {
      const query = gql(`
        query IndexPrices($fromTimestamp: BigInt!) {
          indexPrices(
            orderBy: blockTimestamp, 
            orderDirection: desc,
            first: ${GraphDefaultPageSize} 
          ) {
            id
            updatedAt
            roundId
            blockTimestamp
            blockNumber
            answer
          }
        }
      `);
      
      const indexMetadata = IndexMetadata[index]
      let prices = new Array<{ time: number, value: number }>();
      const { indexPrices } = await graphClient.request(query, {
          fromTimestamp: fromTimestamp,
        }) as any

      prices = indexPrices.map((price: any) => ({
        time: parseInt(price.blockTimestamp),
        value: parseFloat(formatUnits(price.answer, indexMetadata.decimals)),
      }))

      return {
        prices: prices.reverse(),
      }
    },
  })
}

export const useIndexPriceLastUpdate = (index: SupportedIndexes, refetchInterval: number) => {
  const chainId = useChainId()
  const graphClient = useIndexesGraphClient()

  return useQuery({
    queryKey: ['indexPriceLastUpdate', chainId, index],
    enabled: true,
    refetchInterval,
    queryFn: async () => {
      const query = gql(`
        query IndexPriceLastUpdate {
          indexPrices(orderBy: blockTimestamp, orderDirection: desc, first: 1) {
            id
            updatedAt
            roundId
            blockTimestamp
            blockNumber
            answer
          }
        }
      `);

      const indexMetadata = IndexMetadata[index]  
      const { indexPrices } = await graphClient.request(query) as any

      return {
        time: parseInt(indexPrices[0].blockTimestamp),
        value: parseFloat(formatUnits(indexPrices[0].answer, indexMetadata.decimals)),
      }
    },
  })
}
