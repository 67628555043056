import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Dropdown, Image, Stack } from "react-bootstrap";
import { useQueryClient } from '@tanstack/react-query'
import { CBPayInstanceType, initOnRamp } from "@coinbase/cbpay-js";

// import { ReactComponent as CoinbaseLogo } from "../assets/coinbase.svg"
import { ReactComponent as CoinbaseWordLogo } from "../assets/coinbase_wordmark.svg"
import CoinbaseIcon from "../assets/coinbase.png"
import { useAddress, useChainId } from "../hooks/network";


export const PayWithCoinbaseButton: React.FC = () => {
  const { address } = useAddress();
  const chainId = useChainId();
  const queryClient = useQueryClient();
  const [onrampInstance, setOnrampInstance] = useState<CBPayInstanceType | null>();
  const coinbaseUrl = "https://pay.coinbase.com/v3/sell/input";
  const networks = ["ethereum", "base", "arbitrum"]
  const assets = ['ETH', 'USDC', 'wBTC']

  const userAddress = useMemo(() => { 
    return address || '0x1';
  }, [address]);

  const refreshBalances = useCallback(
    () =>
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) =>
          ['IndexesBalances', 'TokenBalances'].includes(queryKey.at(0) as string) &&
          queryKey.includes(chainId),
      }),
    [chainId, queryClient],
  )

  useEffect(() => {
    initOnRamp({
      appId: process.env.REACT_APP_COINBASE_PROJECT_ID || '',
      widgetParameters: {
        addresses: {
          userAddress: networks,
        },
        assets,
        defaultNetwork: 'base',
        defaultExperience: "buy",
      },
      onSuccess: () => {
        refreshBalances();
      },
      onExit: () => {
        refreshBalances();
      },
      onEvent: (event) => {
        console.log('event', event);
      },
      experienceLoggedIn: 'popup',
      experienceLoggedOut: 'popup',
      closeOnExit: true,
      closeOnSuccess: true,
    }, (_, instance) => {
      setOnrampInstance(instance);
    });

    // When button unmounts destroy the instance
    return () => {
      onrampInstance?.destroy();
    };
  },
    // eslint-disable-next-line
    [userAddress]
  );

  const handleClick = () => {
    onrampInstance?.open();
  };

  const handleOfframp = async () => {
    const appId = process.env.REACT_APP_COINBASE_PROJECT_ID || '';
    const redirectUrl = window.location.toString();

    let sellUrl = new URL(coinbaseUrl);
    sellUrl.searchParams.set("appId", appId);
    sellUrl.searchParams.set("partnerUserId", userAddress);
    sellUrl.searchParams.set("addresses", `{"${userAddress}":["${networks.join('","')}"]}`);
    sellUrl.searchParams.set("assets", `["${assets.join('","')}"]`);
    sellUrl.searchParams.set("redirectUrl", redirectUrl);

    // console.log('url', sellUrl);
    window.open(sellUrl, "_blank", "popup:true,height=800,width=500,left=500");
  }

  return (
    <>
      <Dropdown style={{ width: "auto", marginLeft: "-5px" }}>
        <Dropdown.Toggle
          id="dp-coinbase"
          variant="success"
          style={{ border: "none", width: "auto" }}
        >
          <Image src={CoinbaseIcon} width={26} height={26} />
          {/* <CoinbaseLogo width={26} height={26} /> */}
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ width: "11rem", left: "-7.2rem" }}>
          <Dropdown.Item onClick={handleClick}>
            <Stack direction="horizontal" gap={0}>
              {/* <Image src={CoinbaseIcon} width={18} height={18} /> */}
              {/* <CoinbaseLogo width={18} height={18} /> */}
              <CoinbaseWordLogo width={72} height={28} />
              Onramp
            </Stack>  
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleOfframp()}>
            <Stack direction="horizontal" gap={0}>
              {/* <Image src={CoinbaseIcon} width={18} height={18} /> */}
              {/* <CoinbaseLogo width={18} height={18} /> */}
              <CoinbaseWordLogo width={72} height={28} />
              Offramp
            </Stack>  
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {/* <Button
        onClick={handleClick} disabled={!onrampInstance}
        variant="outline-primary"
      >
        <CoinbaseLogo width={26} height={26} />
      </Button> */}
    </>  
  );
};
