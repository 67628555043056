import React, { useState } from "react";
import { Alert, Button, Image, Stack } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

import giftIcon from "../../../assets/gift.png";


export const IncentivesBanner = () => { 
  const [show, setShow] = useState(true);
  
  if (!show) return <></>;
  
  return (
    <Alert onClose={() => setShow(false)} className="cool-box">
      <Stack direction="horizontal">
        <Stack direction="horizontal" className="w-90" gap={2}>
          <Image src={giftIcon} width={20} height={20} />
          <p>
            <span className="text-muted-light bold">178,000 CTX</span> Up for Grabs! Mint TCAP and maximize your earnings with our{" "}
            <a href="/incentives" target="_blank" rel="noreferrer" style={{ color: "#f5f5f5" }}>
              Incentives Program.
            </a>
          </p>
        </Stack>
        <Stack direction="horizontal" className="w-10 justify-content-end">
          <Button variant="outline-primary" className="p-0" onClick={() => setShow(false)}>
            <AiOutlineClose fill="white" size={20} />
          </Button>
        </Stack>  
      </Stack>  
    </Alert>  
  )
}

export const IncentivesBanner2 = () => { 
  const [show, setShow] = useState(true);
  
  if (!show) return <></>;
  
  return (
    <Stack
      direction="horizontal"
      className="justify-content-between align-items-center border-box py-2 px-3 cool-box"
    >
      <Stack direction="horizontal" gap={1}>
        <p>
          178,000 CTX Up for Grabs! mint tcap and check out our incentives program
        </p>
      </Stack>
    </Stack> 
  )
}
