import { useQuery } from '@tanstack/react-query'
import { gql } from '@apollo/client'

import { useAddress, useFuulGraphClient } from '../network'
import { CtxTokenAddresses } from '../../constants/contracts'
import { base } from 'viem/chains'
// import { GraphDefaultPageSize } from '../../utils/graphUtils'


export const useIncentives = () => {
  const graphClient = useFuulGraphClient()

  return useQuery({
    queryKey: ['incentives'],
    enabled: true,
    queryFn: async () => {
      const query = gql(`
        query Incentives {
          projects (where: { projectId: "20" }) {
            projectId
            projectInfoURI
            clientFeeCollector,
            budgets {
              currency,
              amount
            }
          }
        }
      `);
      const { projects } = await graphClient.request(query) as any
      
      console.log("incentives", projects)
      return {
        incentives: projects,
      }
    },
  })
}

export const useUserRewards = () => {
  const address = useAddress()
  const graphClient = useFuulGraphClient()

  return useQuery({
    queryKey: ['userRewards', address.address],
    enabled: address.address !== undefined,
    queryFn: async () => {
      const query = gql(`
        query UserRewards($address: String!, $currency: String!) {
          userBalances (where: { owner_contains_nocase: $address, currency_contains: $currency }) {
            claimed
            availableToClaim
          }
        }
      `);
      const { users } = await graphClient.request(query, {
        address: address.address,
        currency: CtxTokenAddresses[base.id],
      }) as any
      
      // console.log("balances", users)
      return {
        userRewards: users,
      }
    },
  })
}
