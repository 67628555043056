import React, { useState } from "react";
import { Button, Stack } from "react-bootstrap";
import { FaXmark } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from 'react-i18next'

import { VaultsMenu } from "./components/VaultsMenu";
import { IndexForm } from "./components/IndexForm";
import { VaultTxList } from "./constants";



const Indexes = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const [showForm, setShowForm] = useState(false);
  const [updatingVault, setUpdatingVault] = useState(false);
  const [baseAction, setBaseAction] = useState<VaultTxList>(VaultTxList.depositMint);
  const [canChoosePocket, setCanChoosePocket] = useState(false);

  return (
    <Stack
      direction="vertical"
      gap={2}
      className="index-vaults align-items-center w-100 h-100 pt-2 pb-4 mt-1 mb-5"
    >
      {!showForm ? (
        <VaultsMenu
          showForm={(isUpdating: boolean, baseAction: VaultTxList, canChoosePocket: boolean) => { 
            setUpdatingVault(isUpdating);
            setBaseAction(baseAction);
            setCanChoosePocket(canChoosePocket);
            setShowForm(true);
          }}
        />
      ) : (
        <>
          {!updatingVault ? (
            <IndexForm isUpdating={updatingVault} baseAction={baseAction} canChoosePocket={canChoosePocket} onHideForm={() => setShowForm(false)} />    
          ) : (
            <Stack
              className={`align-self-center mt-5 border-primary ${!isMobile ? "w-60" : "w-100"}`}
              style={{ flex: "unset"}}    
            >
              <Stack
                direction="horizontal"
                className="justify-content-between px-0 py-2"
                style={{ borderBottom: "0.5px solid #494866"}}
              >
                <h5 className="ps-2">
                  {baseAction === VaultTxList.depositMint ? `${t("deposit")} / ${t("mint")}` : `${t("burn")} / ${t("withdraw")}`} 
                </h5>
                <Button
                  variant="outline-primary"
                  onClick={() => setShowForm(false)}
                >
                  <FaXmark size={24} fill="#aaa9bf" />
                </Button>
              </Stack>
              <IndexForm isUpdating={updatingVault} baseAction={baseAction} canChoosePocket={canChoosePocket} onHideForm={() => setShowForm(false)} />
            </Stack>  
          )}
        </>      
      )}
    </Stack>
  );  
}

export default Indexes;
